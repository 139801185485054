import { useQuery, useQueryClient } from "react-query";
import api from "../../utils/axios";
import { useAuth } from "../../context/authContext";
import CustomLoader from "../../components/Loader";
import { useEffect, useRef, useState } from "react";
import { sendNotification } from "../../utils/notifications";
import Dropdown from "../../components/layout/Dropdown";
import {} from "react-query";
import Spinner from "../../components/Spinner";

type PaginationProps = {
  allProducts: any[];
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  paginatedData: any;
  countMaximumPages: number;
};

const itemsPerPage = 5;

const Pagination = ({
  allProducts,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  paginatedData,
  countMaximumPages,
}: PaginationProps) => {
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <nav aria-label="Page navigation example">
      <div className="flex items-center -space-x-px h-8 text-sm">
        <button
          disabled={currentPage === 1}
          onClick={handlePrevious}
          className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 ${
            currentPage === 1
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer"
          }`}
        >
          <span className="sr-only">Previous</span>
          <svg
            className="w-2.5 h-2.5 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
        </button>

        <span className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
          {currentPage}
        </span>
        <button
          onClick={handleNext}
          disabled={currentPage === countMaximumPages}
          className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 ${
            currentPage === countMaximumPages
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer"
          }`}
        >
          <span className="sr-only">Next</span>
          <svg
            className="w-2.5 h-2.5 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
};

// ==========function to get all products from shopify store and display in the table=========
const ProductsTable = () => {
  const { authUser } = useAuth();
  const { shop } = authUser;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchExcludedTags, setSearchExcludedTags] = useState("");
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [selected, setSelected] = useState("All");
  const [nextPageInfo, setNextPageInfo] = useState<any>("");
  const [searchedNextPageInfo, setSearchedNextPageInfo] = useState<any>("");
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [customLoader, setCustomLoader] = useState<boolean>(true);
  const [customSpinner, setCustomSpinner] = useState<boolean>(false);
  const debounceTimeout: any = useRef(null);
  const [isAutomaticallyDraftChecked, setIsAutomaticallyDraftChecked] =
    useState<boolean>(false);

  console.log("nextPageInfo", nextPageInfo);
  console.log("shop ====================== 01 ", shop);
  console.log(
    "allProducts.length ====================== 02 ",
    allProducts.length
  );

  //  ==========usequery to  fetch products===============
  useQuery({
    queryKey: ["Products", shop, allProducts.length], // Add `shop` as dependency
    queryFn: async () => {
      console.log("shop inside table");
      try {
        if (nextPageInfo !== "stop") {
          const resp = await api.get(
            `/products?shop=${shop}&page_info=${nextPageInfo}`
          );
          console.log("products resp: 03  ", resp);
          if (resp?.status === 200) {
            const hasNextPage = resp?.data?.next_page_info;
            if (hasNextPage) {
              console.log("Next page URL found", allProducts?.length);
              setNextPageInfo(hasNextPage);
            } else {
              console.log("No next page URL found", allProducts?.length);
              setNextPageInfo("stop");
            }
            setAllProducts((prev) => [...prev, ...resp?.data?.products_data]);
            setCustomLoader(false);
            console.log("***********");

            return resp?.data?.products_data || [];
          }
          console.error("Error fetching products: 04 ", resp?.status);
          return [];
        }
        return [];
      } catch (error) {
        console.error("Error fetching products: 05", error);
        return [];
      }
    },
    enabled: !!shop && searchTerm === "" && searchExcludedTags === "",
  });

  //set all products to empty array when shop changes
  useEffect(() => {
    setNextPageInfo("");
    setAllProducts([]);
    setCustomLoader(true);
  }, [shop]);

  // =========function to handle all products search==========
  const handleAllProducts = (
    allProducts: any[],
    shop: any,
    selectedItems: number[]
  ) => {
    // Filter products based on selected items and tags
    const filteredProducts = allProducts.filter(
      (product) =>
        selectedItems.includes(product.id) &&
        !product.tags.includes("checked") &&
        !product.tags.includes("Checked")
    );
  
    console.log("filteredProducts", filteredProducts);
  
    // Map over filtered products to extract relevant information
    const allProductImages = filteredProducts.map((product) => ({
      product_id: product.id,
      title: product.title,
      product_status: product.status,
      images: product.images.map((image: any) => ({
        src: image.src,
        product_id: product.id,
      })),
    }));
  
    // Pass the processed product images to the compareImages function
    compareImages(allProductImages, shop);
  };
  
  // ===========function to handle the search with single product==========
  const handleImageSearch = async (
    product: {
      images: { src: string; product_id: string }[];
      title: string;
      status: string;
      tags: string;
      id: number;
    },
    shop: string
  ) => {
    try {
      if (!product.images || !Array.isArray(product.images)) {
        return;
      }

      const tag = product.tags.split(",")?.map((term) => term.trim());
      console.log("tag", tag);
      const isPresent = tag.some(
        (item) => item === "checked" || item === "Checked"
      );
      console.log("isPresent", isPresent);

      if (isPresent) {
        console.log("image already checked", tag);
        sendNotification("warning", "Image already checked");
        return;
      }
      const filterImages = [{
        product_id: product.id,
        title: product.title,
        product_status: product.status,
        images: product.images.map((image) => ({
          src: image.src,
          product_id: product.id,
        })),
      }]


      await compareImages(filterImages, shop);

      console.log("filterImages after comparison", filterImages);
    } catch (error) {
      console.error("Error handling image search:", error);
    }
  };
  // const handleAllProducts = (
  //   allProducts: any[],
  //   shop: any,
  //   selectedItems: number[]
  // ) => {
  //   const filteredProducts = allProducts.filter(
  //     (product) =>
  //       selectedItems.includes(product.id) && !product.tags.includes("Checked")
  //   );

  //   const allProductImages = filteredProducts.flatMap(
  //     (product) =>
  //       product?.images?.map((image: any) => ({
  //         title: product.title || "",
  //         src: image.src,
  //         product_id: product.id,
  //         product_status: product.status,
  //       })) || []
  //   );

  //   compareImages(allProductImages, shop);
  // };

  // // ===========function to handle the search with single product==========
  // const handleImageSearch = async (
  //   product: {
  //     images: { src: string; product_id: string }[];
  //     title: string;
  //     status: string;
  //     tags: string;
  //   },
  //   shop: string
  // ) => {
  //   try {
  //     if (!product.images || !Array.isArray(product.images)) {
  //       return;
  //     }

  //     const tag = product.tags.split(",")?.map((term) => term.trim());
  //     console.log("tag", tag);
  //     const isPresent = tag.some(
  //       (item) => item === "checked" || item === "Checked"
  //     );
  //     console.log("isPresent", isPresent);

  //     if (isPresent) {
  //       console.log("image already checked", tag);
  //       sendNotification("warning", "Image already checked");
  //       return;
  //     }
  //     const filterImages = product.images.map((image) => ({
  //       src: image.src,
  //       product_id: image.product_id,
  //       title: product.title,
  //       product_status: product.status,
  //     }));

  //     await compareImages(filterImages, shop);

  //     console.log("filterImages after comparison", filterImages);
  //   } catch (error) {
  //     console.error("Error handling image search:", error);
  //   }
  // };

  const compareImages = async (filterImages: any[], shop: any) => {
    try {
      sendNotification("success", "Searching images...");
      if (!Array.isArray(filterImages)) {
        sendNotification("warning", "Images not found");
        return;
      }
      const updatedFilteredConvertedUrls = filterImages.map((item) => ({
        ...item,
        isAutomaticallyDraftChecked,
      }));
      const resp = await api.post(
        `/products/compareImages?shop=${shop}`,
        JSON.stringify(updatedFilteredConvertedUrls)
      );
      // console.log("response in convert images", resp);
      const result = resp.data;
      console.log("result handle search image", result);
    } catch (error) {
      console.error("Error handling image search:", error);
    }
  };


  const handleHeaderCheckboxChange = (products: any[]) => {
    if (isAllChecked) {
      setSelectedItems([]);
      setIsAllChecked(false);
    } else {
      setSelectedItems(products.map((product) => product.id));
      setIsAllChecked(true);
    }
  };

  const handleCheckboxChange = (productId: any) => {
    setSelectedItems((prevSelectedProducts) => {
      if (prevSelectedProducts.includes(productId)) {
        return prevSelectedProducts.filter((id) => id !== productId);
      } else {
        return [...prevSelectedProducts, productId];
      }
    });
  };

  const filteredProducts = allProducts?.filter((product) => {
    if (!product) return false;
  
    // Split the searchExcludedTags into an array of tags
    const lowerSearchExcludedTags = searchExcludedTags
      .toLowerCase()
      ?.split(",")
      .map((tag) => tag.trim());
  
    // Check if the product's tags contain any of the excluded tags
    // const productTags = product?.tags?.toLowerCase().split(",").map((item:any) => item.trim()) || [];
  
    // Check if any tag in lowerSearchExcludedTags is present in productTags
    const matchesExcludedTags =
        lowerSearchExcludedTags[0] === "" ||
        lowerSearchExcludedTags.every(
          (tag) => !product?.tags?.toLowerCase().includes(tag)
        );
  
    // Check if the product status matches the selected status
    const matchesStatus = selected === "Active" || selected === "Draft"
      ? product?.status?.toLowerCase() === selected.toLowerCase()
      : true;
  
    return matchesExcludedTags && matchesStatus;
  
  }) || [];
  console.log("filteredProducts------", filteredProducts?.length);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredProducts.slice(startIndex, endIndex);
  const countMaximumPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const showPagination = filteredProducts.length > itemsPerPage;


  const searchData=async(searchTerm:string, searchExcludedTags:string, searchedNextPageInfo?:string, pageNumber?:number)=>{
    console.log("searchTerm", searchTerm, "searchExcludedTags", searchExcludedTags, "searchedNextPageInfo", searchedNextPageInfo, "pageNumber", pageNumber);
    try {
      if(pageNumber && searchedNextPageInfo!=="stop"){
      setCurrentPage(pageNumber);
      const resp=await api.get(`/products/searchedProducts?shop=${shop}&searchTerm=${searchTerm}&searchExcludedTags=${searchExcludedTags}&nextPageInfo=${searchedNextPageInfo}`);
      console.log("res inside serach Data", resp);
      if(resp?.data?.products?.length>0){
        if(searchedNextPageInfo === ""){
          console.log("inside if searchData",nextPageInfo);
          setAllProducts(resp?.data?.products)
        }
        else{
          console.log("inside else searchData",nextPageInfo);
          setAllProducts((prev) => [...prev, ...resp?.data?.products]);
        }
        if(resp?.data?.next_page_info?.hasNextPage){
          setSearchedNextPageInfo(resp?.data?.next_page_info?.endCursor)
        }
        else{
          setSearchedNextPageInfo("stop")
        }
      }
      else{
        console.log("No product found",resp);
        setSearchedNextPageInfo("stop")
        setAllProducts([])
      }
    }
    } catch (error) {
      console.log("search data error", error);
    }
    finally{
      // setTimeout(() => {
        setCustomSpinner(false);
      // },5000)
    }
  }

  useEffect(() => {
    console.log("inside searchedNextPageInfo", searchedNextPageInfo);
    if(searchedNextPageInfo!=="stop" && searchedNextPageInfo!==""){
      console.log("inside searchedNextPageInfo if"); 
      searchData(searchTerm, searchExcludedTags, searchedNextPageInfo, currentPage);
    }
    else{
      console.log("inside searchedNextPageInfo esle"); 
    }
  }, [searchedNextPageInfo]);


  useEffect(() => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      console.log(
        "searchTerm",
        searchTerm,
        "searchExcludedTags",
        searchExcludedTags
      );
      if(searchTerm!== "" || searchExcludedTags !== "") {
        console.log("inside debounceTimeout", searchTerm, searchExcludedTags, searchedNextPageInfo, 1);
        setNextPageInfo("");
        searchData(searchTerm, searchExcludedTags, searchedNextPageInfo, 1)
      }
      else{
        setNextPageInfo("");
        setAllProducts([]);
        setCustomLoader(true);
      }
    }, 1000);

    return () => clearTimeout(debounceTimeout.current);
  }, [searchTerm, searchExcludedTags]);
  console.log("allProducts", allProducts);
  console.log("filteredProducts", filteredProducts);
  console.log("customSpinner", customSpinner);
  console.log("selected products", selectedItems, selectedItems.length);

  if (customLoader) return <CustomLoader />;

  return (
    <>
      <div className="w-full h-full flex flex-col gap-2 mt-4">
        <div className="flex justify-between items-center">
          <Dropdown
            selected={selected}
            setSelected={setSelected}
            setCurrentPage={setCurrentPage}
          />
          <div className="flex justify-end items-center gap-4">
            <div className="flex justify-end">
              <input
                type="checkbox"
                checked={isAutomaticallyDraftChecked}
                id="automatic-draft"
                onChange={() => {
                  setIsAutomaticallyDraftChecked(!isAutomaticallyDraftChecked);
                }}
              />
              <label htmlFor="automatic-draft" className="ml-2">
                Automatically Draft
              </label>
            </div>
            {selectedItems.length > 1 && (
              <button
                className="border-solid border-2 border-black px-4 py-1 rounded-md bg-slate-700 text-white"
                onClick={() =>
                  handleAllProducts(allProducts, shop, selectedItems)
                }
              >
                Search All
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center flex-col h-full border-solid border-2 border-gray-300 rounded-lg bg-slate-50">
          <div className="w-full p-2 flex justify-between gap-5">
            <div className="relative w-full">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Products..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                  setAllProducts([]);
                  setSearchedNextPageInfo("");
                  setSelectedItems([]);
                  if(e.target.value !=="" || searchExcludedTags !==""){
                    setCustomSpinner(true);
                  }
                }}
              />
              {searchTerm && searchedNextPageInfo!== "stop" && <div className="absolute inset-y-0 end-10 flex items-center ps-3 pointer-events-none">
                <Spinner width={5}/>
              </div>}
            </div>

            <div className="relative w-full">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Excluded Tag Products..."
                value={searchExcludedTags}
                onChange={(e) => {
                  setSearchExcludedTags(e.target.value);
                  setAllProducts([]);
                  setSearchedNextPageInfo("");
                  setCurrentPage(1);
                  if(e.target.value !=="" || searchTerm !==""){
                    setCustomSpinner(true);
                  }
                }}
              />
              {searchExcludedTags && searchedNextPageInfo!== "stop" && <div className="absolute inset-y-0 end-10 flex items-center ps-3 pointer-events-none">
                <Spinner width={5}/>
              </div>}
            </div>
          </div>
          <div className="w-full p-2">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400 rounded-lg">
                <thead className="text-xs text-black uppercase bg-gray-50">
                  <tr className="bg-blue-700 text-white">
                    <th scope="col" className="p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          onChange={() =>
                            handleHeaderCheckboxChange(filteredProducts)
                          }
                          checked={isAllChecked}
                        />
                        <label
                          htmlFor="checkbox-all-search"
                          className="sr-only"
                        >
                          checkbox
                        </label>

                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center"
                      colSpan={1}
                    >
                      Product Image
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center"
                      colSpan={1}
                    >
                      Product Title
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Categories
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Tags
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="text-black">
                  { customSpinner || (filteredProducts.length === 0 && searchedNextPageInfo!=="stop") ? (
                    <tr>
                      <td colSpan={7} className="text-center py-4">
                        <Spinner width={8}/>
                      </td>
                    </tr>
                  ): paginatedData.length > 0 ? (
                    paginatedData.map((product: any, index: number) => (
                      <tr className="bg-white border-b" key={index}>
                        <td className="p-4">
                          <div className="flex items-center">
                            <input
                              id={`checkbox-${index}`}
                              type="checkbox"
                              value={product?.id}
                              checked={selectedItems.includes(product?.id)}
                              onChange={() => handleCheckboxChange(product.id)}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor={`checkbox-${index}`}
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="pl-10 py-4 text-center">
                          <div className="w-12 h-12 flex items-center gap-3">
                            <img
                              src={
                                product?.images[0]?.src ||
                                "https://via.placeholder.com/150"
                              }
                              alt={product?.images[0]?.alt || "Product image"}
                              className="w-full h-full object-cover rounded-md"
                            />
                          </div>
                        </td>
                        <td className="pr-6 py-4">{product?.title}</td>
                        <td className="px-6 py-4 text-center">
                          {product?.product_type}
                        </td>
                        <td className="px-6 py-4 text-center">
                          {product?.tags}
                        </td>
                        <td className="px-6 py-4 text-center">
                          {product?.status?.toLowerCase()}
                        </td>
                        <td className="px-6 py-4 text-center">
                          <button
                            className="border-solid border-2 border-black px-4 py-1 rounded-md bg-slate-700 text-white"
                            onClick={() =>
                              handleImageSearch(product, shop as string)
                            }
                          >
                            Search
                          </button>
                        </td>
                      </tr>
                    ))
                  )  : (
                    <tr>
                      <td colSpan={7} className="text-center py-4">
                        No Results
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {showPagination && !customSpinner && (
                <div className="py-8 flex justify-center">
                  <Pagination
                    allProducts={filteredProducts}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    paginatedData={paginatedData}
                    countMaximumPages={countMaximumPages}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ProductsTable };