import { useQuery } from "react-query";
import api from "../../utils/axios";
import { useAuth } from "../../context/authContext";
import CustomLoader from "../../components/Loader";
import { useState } from "react";
import MatchingRow from "../../components/layout/main-layout/MatchingRow";
type PaginationProps = {
  products: any[];
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  paginatedData: any;
  countMaximumPages: number;
};

const itemsPerPage = 5;

const Pagination = ({
  products,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  paginatedData,
  countMaximumPages,
}: PaginationProps) => {
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <nav aria-label="Page navigation example">
      <div className="flex items-center -space-x-px h-8 text-sm">
        <button
          disabled={currentPage === 1}
          onClick={handlePrevious}
          className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 ${
            currentPage === 1
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer"
          }`}
        >
          <span className="sr-only">Previous</span>
          <svg
            className="w-2.5 h-2.5 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
        </button>

        <span className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
          {currentPage}
        </span>
        <button
          onClick={handleNext}
          disabled={currentPage === countMaximumPages}
          className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 ${
            currentPage === countMaximumPages
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer"
          }`}
        >
          <span className="sr-only">Next</span>
          <svg
            className="w-2.5 h-2.5 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
};

// ==========function to get all products from shopify store and display in the table=========
const MatchingProducts = () => {
  const { authUser } = useAuth();
  const { shop } = authUser;
  const [currentPage, setCurrentPage] = useState(1);
  
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  console.log("shop inside table", shop);
  const { data: products, isLoading } = useQuery({
    queryKey: ["Matched Products Data", shop],
    queryFn: async () => {
      const resp = await api.get(`/products/matchedProducts?shop=${shop}`);
      console.log("products resp:12 ", resp);
      console.log(" matched products resp: ", resp?.data?.matchedProducts);
      if (resp?.data?.CODE === "SUCCESS") {
        console.log("products: ", resp?.data?.matchedProducts);
        return resp?.data?.matchedProducts;
      }
      return [];
    },
  });
  console.log("matched products: ", products);

  const handleHeaderCheckboxChange = (products: any[]) => {
    if (isAllChecked) {
      // If header checkbox is already checked, uncheck it and clear selection
      setSelectedItems([]);
      setIsAllChecked(false);
    } else {
      // Check the header checkbox and select all products
      setSelectedItems(products.map((product) => product.product_id));
      setIsAllChecked(true);
    }
  };


  

  const handleCheckboxChange = (productId: any) => {
    setSelectedItems((prevSelectedProducts) => {
      // Check if the productId is already selected
      if (prevSelectedProducts.includes(productId)) {
        // If unchecked, remove the productId
        return prevSelectedProducts.filter((id) => id !== productId);
      } else {
        // If checked, add the productId
        return [...prevSelectedProducts, productId];
      }
    });
  };
  console.log("selectedItems", selectedItems);

  // Filter products based on search term
  const filteredProducts =
    products?.filter(
      (product: any) =>
        product?.product_title
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        // product?.product_type
        //   ?.toLowerCase()
        //   .includes(searchTerm.toLowerCase()) ||
        // product?.tags?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product?.product_id === parseInt(searchTerm)
    ) || [];

  console.log("filteredProducts", filteredProducts);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredProducts.slice(startIndex, endIndex);

  const countMaximumPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const showPagination = filteredProducts.length > itemsPerPage;

  if (isLoading) return <CustomLoader />;

  return (
    <div className="w-full h-full display flex flex-col gap-2 mt-4">
      <div className="flex justify-center items-center flex-col h-full border-solid border-2 boredr-gray-300 rounded-lg bg-slate-50">
        <div className="w-full p-2">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Products..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
        <div className="w-full p-2">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400 rounded-lg">
              <thead className="text-xs text-black uppercase bg-gray-50">
                <tr className="bg-blue-700 text-white">
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        onChange={() => handleHeaderCheckboxChange(products)}
                        checked={isAllChecked}
                      />
                      <label htmlFor="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 " colSpan={1}>
                    Product Image
                  </th>
                  <th scope="col" className="px-6 py-3 text-center" colSpan={1}>
                    Product Title
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    Matched Image
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    Matched Percentage
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-black">
                {paginatedData.length > 0 ? (
                  paginatedData.map((product: any, index: number) => (
                    <MatchingRow 
                      product={product}
                      key={index}
                      selectedItems={selectedItems}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center py-4">
                      No Results
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {showPagination && (
              <div className="py-8 flex justify-center">
                <Pagination
                  products={filteredProducts}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  paginatedData={paginatedData}
                  countMaximumPages={countMaximumPages}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { MatchingProducts };
