import { useForm } from "react-hook-form";
import TextError from "../shared/TextError";
import { useMutation, useQueryClient } from "react-query";
import { addEmail } from "../../services/api/auth";
import { sendNotification } from "../../utils/notifications";

type PropTypes = {
  showModal: boolean;
  setShowModal: any;
  activeEmail: any;
  sendgridApiKey: any;
};

export const SendGridEmailModal = ({
  showModal,
  setShowModal,
  activeEmail,
  sendgridApiKey,
}: PropTypes) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationKey: ["sendgrid_email"],
    mutationFn: async (data: any) => {
      let res = await addEmail(data);
      if (res?.status === 200) {
        if (res?.data?.CODE === "SUCCESS") {
          sendNotification(
            "success",
            "Your changes has been successfully saved"
          );
          setShowModal(false);
        } else if (res?.data?.CODE === "ALREADY_EXSIST") {
          sendNotification("warning", "Email is already exist");
        } else if (res?.data?.CODE === "USER_STORE_NOT_FOUND") {
          sendNotification("error", "Please add store to continue.");
        }
      } else {
        sendNotification("warning", res?.response?.data?.message);
      }
      return res;
    },
    onSuccess: (resp: any) => {
      console.log("resp: ", resp);
      if (resp?.data?.CODE === "SUCCESS") {
        queryClient.invalidateQueries("authorized_email");
      }
    },
  });

  const onSubmit = async (data: any) => {
    const { sendgrid_email, sendgrid_api_key } = data;
    if (!sendgrid_email || !sendgrid_api_key) return;
    mutate({ sendgrid_email, sendgrid_api_key });
  };

  return (
    <>
      {showModal ? (
        <div
          id="popup-modal"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex__center"
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow ">
              <button
                type="button"
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                onClick={() => setShowModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                <div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full py-5"
                  >
                    <div className="form-control mb-6 flex flex-col">
                      <label className="text-left mb-3">SendGrid Email</label>
                      <input
                        defaultValue={activeEmail}
                        type="text"
                        {...register("sendgrid_email", {
                          required: {
                            value: true,
                            message: "Email is required.",
                          },
                          pattern: {
                            value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                            message: "Email is not valid.",
                          },
                        })}
                        autoComplete="off"
                        spellCheck={false}
                        className="form__input mb-4"
                      />
                      <span className="text-left">
                        {errors.sendgrid_email && (
                          <TextError
                            color="dark"
                            msg={errors.sendgrid_email.message}
                          />
                        )}
                      </span>
                    </div>
                    <div className="form-control mb-6 flex flex-col">
                      <label className="text-left mb-3">SendGrid Api key</label>
                      <input
                        defaultValue={sendgridApiKey}
                        type="text"
                        {...register("sendgrid_api_key", {
                          required: {
                            value: true,
                            message: "SendGrid api key is required.",
                          },
                          // pattern: {
                          //   value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          //   message: "Email is not valid.",
                          // },
                        })}
                        autoComplete="off"
                        spellCheck={false}
                        className="form__input mb-4"
                      />
                      <span className="text-left">
                        {errors.sendgrid_api_key && (
                          <TextError
                            color="dark"
                            msg={errors.sendgrid_api_key.message}
                          />
                        )}
                      </span>
                    </div>

                    <button type="submit" className="custom__button !w-full">
                      {isLoading ? "Loading..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
