import React, { useState } from 'react';

const Dropdown = ({selected, setSelected, setCurrentPage}:{ selected: string, setSelected: any , setCurrentPage: any}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value:string) => {
    setSelected(value);
    setCurrentPage(1);
    setIsOpen(false);
  };

  return (
    <div className="flex justify-between gap-2">
      <div className='relative'>
        <button
          id="dropdownDefaultButton"
          onClick={handleDropdownToggle}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
        >
          {selected}
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        {isOpen && (
          <div
            id="dropdown"
            className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute"
          >
            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
              <li>
              <button
                onClick={() => handleSelect('All')}
                className={`w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
                  selected === "All" ? 'bg-gray-100 dark:bg-gray-600' : ''
                }`}
              >
                All
              </button>
              </li>
              <li>
                <button onClick={() => handleSelect('Active')} 
                className={`w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
                  selected === "Active" ? 'bg-gray-100 dark:bg-gray-600' : ''
                }`}>
                  Active
                </button>
              </li>
              <li>
                <button onClick={() => handleSelect('Draft')} 
                  className={`w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
                  selected === "Draft" ? 'bg-gray-100 dark:bg-gray-600' : ''
                  }`}>
                  Draft
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
