import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import LoginPage from "./views/pages/LoginPage";
import SignupPage from "./views/pages/Signup";
import VerificationPage from "./views/pages/VerificationPage";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import DashboardPage from "./views/pages/Dashboard";
import { QueryProvider } from "./components/providers";
// import { OrdersHistoryPage } from "./views/pages/OrdersHistory";
import { AuthorizedUsersPage } from "./views/pages/AuthorizedUsers";
import { useAuth } from "./context/authContext";
import EmailSettingsPage from "./views/pages/EmailSettings";
import { ProductsTable } from "./views/pages/ProductsTable";
import { MatchingProducts } from "./views/pages/MatchingProducts";

const App = () => {
  const { IS_ADMIN } = useAuth();
  return (
    <QueryProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicRoutes />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="" element={<SignupPage />} />
            <Route path="*" element={<SignupPage />} />
          </Route>
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="verification" element={<VerificationPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            {IS_ADMIN ? (
              <Route
                path="authorized-users"
                element={<AuthorizedUsersPage />}
              />
            ) : (
              <>
                {/* <Route path="orders-history" element={<OrdersHistoryPage />} /> */}
                <Route path="products" element={<ProductsTable />} />
                <Route path="matching-products" element={<MatchingProducts />} />
                <Route path="email-settings" element={<EmailSettingsPage />} />
              </>
            )}
            <Route path="*" element={<DashboardPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryProvider>
  );
};

export default App;
