import { useMutation, useQuery } from "react-query";
import api from "../../utils/axios";
import { useState } from "react";
import { SendGridEmailModal } from "../../components/user/SendGridEmailModal";
import { FiEye, FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { useAuth } from "../../context/authContext";
import { sendNotification } from "../../utils/notifications";
import { CustomModal } from "../../components/CustomModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const quillModules = {
  toolbar: [
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ],
};

const shorthands = [
  {
    id: 1,
    symbol: "order_number",
    description: "Shipping Order Number",
  },
  {
    id: 2,
    symbol: "first_name",
    description: "User first name",
  },
  {
    id: 3,
    symbol: "user_address",
    description: "User Shipping address",
  },
];

export default function EmailSettingsPage() {
  const { authUser } = useAuth();
  const [showSendGridModal, setShowSendGridModal] = useState(false);
  const [isTemplateExist, setIsTemplateExist] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [subject, setSubject] = useState("Shipping update order order_number");

  const { data }: { data: any } = useQuery({
    queryKey: ["authorized_email"],
    queryFn: async () => {
      const { data } = await api.get("/auth/email");
      return data || {};
    },
  });

  const activeEmail = data?.sendgrid_email || "";
  const sendgridApiKey = data?.sendgrid_api_key || "";

  const _email = `<div style="background: #f4f4f4; padding: 30px 0">
  <div style="max-width:620px; margin: 0 auto;padding:40px;background: white"><p>Hello <strong>first_name</strong></p><p><br></p><p>We’re reaching out about your order order_number to double check the address you provided. Our system says there’s an error in the address you provided: user_address <p></p><p><br></p><p>Could you please verify if this address is correct or send us the correct address?</p><p><br></p><p>Thank you</p><p>Kind regards,</p><p>Tanja | WOLFF Kundenservice</p>
  </div>
</div>
`;

  const [email, setEmail] = useState(_email);
  const { refetch } = useQuery({
    queryKey: ["fetch_email_template", authUser?.shop],
    queryFn: async () => {
      const rep = await api.get(`/auth/email-template/${authUser?.shop}`);
      return rep;
    },
    onSuccess: (resp: any) => {
      if (resp?.data?.CODE === "SUCCESS") {
        setIsTemplateExist(true);
        setEmail(resp?.data?.email?.content);
        setSubject(resp?.data?.email?.subject);
      }
    },
    onError: (resp: any) => {
      if (resp?.response?.data?.CODE === "NOT_FOUND") {
        setIsTemplateExist(false);
      }
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async ({
      subject,
      email,
    }: {
      subject: string;
      email: string;
    }) => {
      const resp = await api.post("/auth/email-template", {
        shop: authUser?.shop,
        email: {
          subject,
          content: email,
        },
      });
      return resp;
    },
    onSuccess: (resp: any) => {
      if (resp.data.CODE === "SUCCESS") {
        refetch();
        setIsTemplateExist(true);
        sendNotification("success", "Email tempalte has successfully saved");
        setShowModal(false);
      }
      if (resp.data.CODE === "UPDATED") {
        refetch();
        setIsTemplateExist(true);
        setShowModal(false);
        sendNotification("success", "Email tempalte has successfully updated");
      }
    },
    onError: (resp: any) => {
      if (
        resp?.response?.data?.CODE === "BAD_REQUEST" ||
        resp?.response?.data?.CODE === "NOT_FOUND"
      ) {
        sendNotification(
          "warning",
          "Oops! Unable to saved your email template at the moment. Please try again later."
        );
      }
    },
  });
  const { mutate: deleteTemplate } = useMutation({
    mutationFn: async (shop: any) => {
      const resp = await api.delete(`/auth/email-template/${shop}`);
      return resp;
    },
    onSuccess: (resp: any) => {
      if (resp?.data?.CODE === "SUCCESS") {
        setEmail(_email);
        setSubject("Shipping update order order_number");
        setIsTemplateExist(false);
        sendNotification("success", "Email tempalte has successfully deleted");
        setShowModal(false);
      }
    },
    onError: (resp: any) => {
      if (
        resp?.response?.data?.CODE === "BAD_REQUEST" ||
        resp?.response?.data?.CODE === "NOT_FOUND"
      ) {
        sendNotification(
          "warning",
          "Oops! Unable to delete your email template at the moment. Please try again later."
        );
      }
    },
  });

  const renderTable = () => {
    return (
      <div className="relative overflow-x-auto my-8">
        <p className="text-sm py-4">
          *** Use these shorthands in the email template below.
        </p>
        <table className="w-full text-sm text-left rtl:text-right ">
          <thead className="text-xs  uppercase bg-gray-50 ">
            <tr className="bg-blue-700 text-white">
              <th scope="col" className="px-6 py-3">
                Shorthands
              </th>
              <th scope="col" className="px-6 py-3">
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {shorthands.map((shorthand) => {
              return (
                <tr className="bg-white border-b" key={shorthand.id}>
                  <th
                    scope="row"
                    className="px-6 py-4 font-normal whitespace-nowrap "
                  >
                    {shorthand.symbol}
                  </th>
                  <td className="px-6 py-4">{shorthand.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleModelChange = (event: any) => {
    if (!event) return;
    setEmail(event);
  };
  const handleSubmitEmail = () => {
    if (!subject) {
      sendNotification("warning", "Email Subject is required");
      return false;
    }
    if (!email) {
      sendNotification("warning", "Email Content is required");
      return false;
    }
    mutate({ subject, email });
  };

  const renderEmailSubject = () => {
    return (
      <div className="flex flex-col gap-4">
        <label>Subject</label>
        <input
          type="text"
          className="py-3 pl-3 border rounded-md"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          spellCheck={false}
        />
      </div>
    );
  };

  return (
    <>
      {showSendGridModal ? (
        <SendGridEmailModal
          showModal={showSendGridModal}
          setShowModal={setShowSendGridModal}
          activeEmail={activeEmail}
          sendgridApiKey={sendgridApiKey}
        />
      ) : null}

      {/* email template */}
      {showModal ? (
        <CustomModal
          showModal={showModal}
          setShowModal={setShowModal}
          title="EMAIL TEMPLATE"
          children={
            <>
              <div className="my-5">
                {renderEmailSubject()}
                {renderTable()}
              </div>
              <ReactQuill
                theme="snow"
                value={email}
                onChange={handleModelChange}
                modules={quillModules}
              />
              <div className="my-5 flex justify-end gap-4">
                <button
                  className="custom__button !bg-white border !text-black"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button className="custom__button" onClick={handleSubmitEmail}>
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              </div>
            </>
          }
        />
      ) : null}
      {/* email settings */}
      <>
        <div className="my-4 bg-white shadow-sm rounded-lg">
          <div className="flex flex-col gap-4 p-8">
            {!authUser?.shop ? (
              <p className="text-gray-400 text-sm py-4">
                ***You cannot access any settings unless you add store.
              </p>
            ) : null}
            {activeEmail ? (
              <>
                <label className="font-semibold">Active Sender Email</label>
                <input
                  type="text"
                  readOnly
                  className="border rounded-md py-2 pl-3"
                  value={activeEmail}
                />
                <button
                  className="custom__button"
                  onClick={() => setShowSendGridModal(true)}
                >
                  Update Sender Email
                </button>
              </>
            ) : (
              <button
                type="button"
                className="custom__button
          "
                onClick={() => setShowSendGridModal(true)}
                disabled={activeEmail || !authUser?.shop}
              >
                Add Sendgrid Crendentials
              </button>
            )}
            <div className="mt-8 text-sm text-left flex flex-col gap-4">
              <h3>
                Note: Please Follow the below steps to get the sendgrid
                credentials.
              </h3>
              <h1 className="font-semibold text-xl">
                Single Sender Verification
              </h1>
              <p>1. Please navigate to the following</p>
              <a
                target="_blank"
                href="https://app.sendgrid.com/settings/sender_auth/senders"
                className="text-blue-500 underline cursor-pointer"
                rel="noreferrer"
              >
                https://app.sendgrid.com/settings/sender_auth/senders
              </a>
              <p>
                2. Click on the "Create New Sender" button and provide the
                necessary details.
              </p>
              <p>
                3. Once you have successfully created the sender, please copy
                the "From" email address and paste it into the "SendGrid Email"
                field.
              </p>

              <h1 className="font-semibold text-xl">Creating API Key</h1>
              <p>
                1. Creating API Key for .SendGrid Visit the SendGrid guide for
                integrating with Node.js at:
              </p>
              <a
                target="_blank"
                href="https://app.sendgrid.com/guide/integrate/langs/nodejs"
                className="text-blue-500 underline cursor-pointer"
                rel="noreferrer"
              >
                https://app.sendgrid.com/guide/integrate/langs/nodejs
              </a>
              <p>
                2. Enter your desired API key name and click on the "Create API
                Key" button.
              </p>
              <p>
                3. Copy the generated API key and paste it into the "SendGrid
                API Key" field.
              </p>
            </div>
          </div>
        </div>
        <div className="my-4 bg-white shadow-sm rounded-lg">
          <div className="flex flex-col gap-4 p-8">
            <p className="font-semibold">Email template Actions</p>
            {authUser?.shop ? (
              <ul className="flex flex-col gap-4">
                {isTemplateExist ? (
                  <>
                    <li className="flex gap-4 items-center">
                      <button
                        type="button"
                        className="custom__button"
                        onClick={() => setShowModal(true)}
                      >
                        <FiEdit />
                      </button>
                      <p className="text-sm">
                        Click to edit the email template
                      </p>
                    </li>
                    <li className="flex gap-4 items-center">
                      <button
                        type="button"
                        className="custom__button"
                        onClick={() => deleteTemplate(authUser?.shop)}
                      >
                        <MdDelete />
                      </button>
                      <p className="text-sm">
                        Click to delete the email template
                      </p>
                    </li>
                  </>
                ) : (
                  <li className="flex gap-4 items-center">
                    <button
                      type="button"
                      className="custom__button"
                      onClick={() => setShowModal(true)}
                    >
                      <FiEye />
                    </button>
                    <p className="text-sm">Click to see the email template</p>
                  </li>
                )}
              </ul>
            ) : (
              <p className="text-center text-gray-400">No access</p>
            )}
          </div>
        </div>
      </>
    </>
  );
}
