import { Link } from "react-router-dom";
import { useAuth } from "../../context/authContext";

export default function UserMenu() {
  const { authUser, userLogout, IS_ADMIN } = useAuth();

  return (
    <div className="relative pl-8">
      <div className="border group">
        <button
          id="dropdownUserAvatarButton"
          className="absolute top-0 right-0 border  md:me-0 flex__center w-10 h-10 rounded-full bg-blue-700 text-base text-white font-semibold 
        "
          type="button"
        >
          {authUser?.name && authUser?.name[0]}
          <div
            id="dropdownAvatar"
            className="z-10 font-normal text-left absolute top-[102%] right-0  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 hidden group-hover:block text-black"
          >
            <div className="px-4 py-3 text-sm ">
              <div>{authUser?.name}</div>
              <div className="font-medium truncate">{authUser?.email}</div>
            </div>
            <ul
              className="py-2 text-sm "
              aria-labelledby="dropdownUserAvatarButton"
            >
              <li>
                <Link
                  to="/dashboard"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-blue-800 dark:hover:text-white"
                >
                  Dashboard
                </Link>
              </li>
              {IS_ADMIN ? (
                <>
                  <li>
                    <Link
                      to="/authorized-users"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-blue-800 dark:hover:text-white"
                    >
                      Authorzied Users
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      to="/products"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-blue-800 dark:hover:text-white"
                    >
                      Products
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/matching-products"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-blue-800 dark:hover:text-white"
                    >
                      Matching Products
                    </Link>
                  </li>

                  {/* <li>
                    <Link
                      to="/email-settings"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-blue-800 dark:hover:text-white"
                    >
                      Email Settings
                    </Link>
                  </li> */}
                </>
              )}
            </ul>
            <div className="py-2" onClick={userLogout}>
              <Link
                to="/login"
                className="block px-4 py-2 text-sm dark:hover:bg-blue-800 dark:hover:text-white"
              >
                Logout
              </Link>
            </div>
          </div>
        </button>
      </div>
      {/* 
      {true && (
      )} */}
    </div>
  );
}
